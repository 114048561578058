<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog v-model="dialogPopup" max-width="1400px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Thêm Popup</span
              >
              <span class="headline" v-else>Sửa Popup</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogPopup = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row class="">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Name:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-text-field
                        v-model="name_input"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Avatar:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4" id="avatar-popup">
                      <b-form-file
                        @change="onChange"
                        placeholder="Chọn tệp tin hoặc cancel để xóa"
                        accept="image/*"
                      ></b-form-file>
                      <template v-if="avatar_data != null">
                        <b-img
                          fluid
                          thumbnail
                          :rounded="true"
                          :src="avatar_data"
                        ></b-img>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Url:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-text-field
                        v-model="url_input"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Active:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-switch
                        class="mt-0 pt-1 ml-2"
                        v-model="is_active"
                        :label="is_active ? 'Yes' : 'No'"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Frequency:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-text-field
                        type="number"
                        outlined
                        dense
                        v-model="frequency_input"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditPopup(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditPopup(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogPopup = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
export default {
  name: "PopupForm",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    popup: {},
  },
  data() {
    return {
      is_call_api: false,
      name_input: "",
      popup_id: null,
      is_active: true,
      avatar_data: null,
      avatar_file: null,
      url_input: null,
      frequency_input: null,
    };
  },
  computed: {
    dialogPopup: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogPopup(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  methods: {
    setDataForm() {
      if (this.type_form === "add") {
        this.name_input = "";
        this.frequency_input = null;
        this.is_active = true;
        this.avatar_data = null;
        this.avatar_file = null;
        this.url_input = "";
        this.setDefaultLabelAvatar();
      } else {
        let data = JSON.parse(JSON.stringify(this.popup));
        this.popup_id = data.id;
        this.name_input = data.name;
        this.url_input = data.url;
        this.frequency_input = data.frequency;
        this.is_active = data.active === 1;
        this.avatar_data = data.avatar;
        this.avatar_file = null;
        this.setDefaultLabelAvatar();
      }
    },
    validateForm() {
      let flat = true;
      if (this.avatar_data === null || this.avatar_data === "") {
        this.$toasted.error("Hãy nhập file ảnh !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.name_input === null || this.name_input === "") {
        this.$toasted.error("Hãy nhập name!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.frequency_input <= 0) {
        this.$toasted.error("Hãy nhập frequency và phải lớn hơn 0!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.avatar_file != null) {
        if (
          !(
            this.getExtensionFile(this.avatar_file) === "jpeg" ||
            this.getExtensionFile(this.avatar_file) === "jpg" ||
            this.getExtensionFile(this.avatar_file) === "png"
          )
        ) {
          this.$toasted.error("Avatar không đúng định dạng ảnh !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      return flat;
    },
    btnAddOrEditPopup(type) {
      let validateForm = this.validateForm();
      if (!validateForm) {
        return;
      }
      let vm = this;
      let formData = new FormData();
      formData.append("name", this.name_input);
      formData.append("frequency", this.frequency_input);
      formData.append("active", this.is_active ? 1 : 0);
      if (!(this.url_input === null || this.url_input === ""))
        formData.append("url", this.url_input);
      if (this.avatar_file != null)
        formData.append("avatar_file", this.avatar_file);
      if (type === 1) {
        ApiService.post("prep-app/web-popup", formData)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogPopup = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        formData.append("_method", "PUT");
        ApiService.post("prep-app/web-popup/" + vm.popup_id, formData)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogPopup = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }

      this.is_call_api = true;
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    onChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        vm.avatar_data = null;
        vm.avatar_file = null;
        return;
      }
      vm.avatar_file = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        vm.avatar_data = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    setDefaultLabelAvatar() {
      let ele = document.getElementById("avatar-popup");
      if (ele) {
        ele.firstChild.children[1].firstChild.textContent =
          "Chọn tệp tin hoặc cancel để xóa";
      }
    },
    getExtensionFile(file) {
      let filename = file.name;
      return filename.split(".").pop();
    },
  },
};
</script>

<style scoped></style>
