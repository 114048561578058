<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí Popup</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogPopup"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm Popup</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Avatar</th>
                    <th scope="col">Frequency</th>
                    <th scope="col">Active</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(popup, i) in popups">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ popup.name }}
                        </p>
                      </td>
                      <td>
                        <v-img
                          v-if="popup.avatar"
                          :src="popup.avatar"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </td>

                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ popup.frequency }}
                        </p>
                      </td>
                      <td>
                        <span style="width: 137px" v-if="popup.active === 0"
                          ><span
                            class="label label-warning label-dot mr-2"
                          ></span
                          ><span class="font-weight-bold text-warning"
                            >Không</span
                          ></span
                        >
                        <span style="width: 137px" v-if="popup.active === 1"
                          ><span
                            class="label label-primary label-dot mr-2"
                          ></span
                          ><span class="font-weight-bold text-primary"
                            >Có</span
                          ></span
                        >
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editPopup(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="openDiaLogPosition(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-crosshairs</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Position</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="btnDeletePopup(popup.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <PopupForm
      :show_dialog="dialogPopup"
      :type_form="type_form"
      :popup="popup"
      @setDialog="(e) => (this.dialogPopup = e)"
      @resetData="getAllPopup()"
    />

    <PositionWeb
      :show_dialog="dialogPositionWeb"
      :list_web_page="list_web_page"
      :data_position_model="data_position_popup"
      @setDialog="(e) => (this.dialogPositionWeb = e)"
      @submitPosition="(e) => this.submitPosition(e)"
    />
  </v-app>
</template>

<script>
// import PositionWeb from "../top-message/modal/PositionWeb";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
import PopupForm from "./modal/PopupForm";
import PositionWeb from "../top-message/modal/PositionWeb";

export default {
  name: "PopupList",
  components: {
    PositionWeb,
    PopupForm,
    // PositionWeb,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      popups: [],
      dialogPopup: false,
      type_form: null,
      popup: {},
      dialogPositionWeb: false,
      list_web_page: [],
      popup_id: null,
      data_position_popup: [],
    };
  },
  created() {
    this.getAllPopup();
    this.listWebPage();
  },
  methods: {
    async getAllPopup() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/web-popup");
        if (res.status === 200) {
          vm.popups = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDiaLogPopup() {
      this.type_form = "add";
      this.dialogPopup = true;
    },
    editPopup(index) {
      this.popup = this.popups[index];
      this.type_form = "update";
      this.dialogPopup = true;
    },
    async listWebPage() {
      let vm = this;
      await ApiService.get("prep-app/list-web-page?").then(function (response) {
        if (response.status === 200) {
          vm.list_web_page = response.data.map(function (page) {
            page.checked = false;
            if (page.items.length > 0) {
              page.items = page.items.map(function (item) {
                item.checked = false;
                return item;
              });
            }
            return page;
          });
        }
      });
    },
    btnDeletePopup(popup_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.delete("prep-app/web-popup/" + popup_id)
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllPopup();
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              vm.is_call_api = false;
            });
        }
      });
    },
    async openDiaLogPosition(index_popup) {
      let vm = this;
      this.popup_id = this.popups[index_popup].id;

      this.is_call_api = true;
      await ApiService.get(
        "prep-app/web-popup/" + this.popup_id + "/get-position-web"
      ).then(function (res) {
        if (res.status === 200) {
          vm.is_call_api = false;
          vm.data_position_popup = res.data;
          vm.dialogPositionWeb = true;
        }
      });
    },
    submitPosition(dataPosition) {
      let vm = this;
      let data = {
        position: dataPosition,
      };
      this.is_call_api = true;
      ApiService.post(
        "prep-app/web-popup/" + this.popup_id + "/save-position-web?",
        data
      ).then(function (res) {
        if (res.status === 200) {
          vm.$toasted.success("Lưu position thành công !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          vm.is_call_api = false;
          vm.dialogPositionWeb = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
